.footer {
    background-color: #ffe386;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .logo {
    width: 100px;
    margin-bottom: 10px;
  }
  
  .address,
  .technical-assistance {
    margin: 10px 0;
    line-height: 27px;
  }
  
  .footer-links a {
    margin: 0 10px;
    color: #000;
    text-decoration: none;
    font-weight: 500;
   
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .copyright {
    margin-top: 20px;
    font-size: 15px;
  }
  